.PanelHeader {
  position: relative;
}

.PanelHeader--vis.PanelHeader--fixed::before {
  display: block;
  content: "";
}

.PanelHeader:not(.PanelHeader--vis):not(.PanelHeader--fixed) {
  height: 0;
}

.PanelHeader__fixed {
  z-index: 10;
}

.PanelHeader__in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;
  background: var(--header_background);
}

.PanelHeader--trnsp .PanelHeader__in {
  background: transparent;
}

.PanelHeader--shadow .PanelHeader__in {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
}

.PanelHeader__before {
  box-sizing: border-box;
  color: var(--header_tint);
  display: flex;
  flex-shrink: 0;
}

.PanelHeader__content {
  overflow: hidden;
}

.PanelHeader__content > * {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PanelHeader__content-in {
  color: var(--header_text);
  font-weight: 500;
  font-family: var(--font-display);
}

.PanelHeader::before,
.PanelHeader__in {
  height: var(--panelheader_height);
  padding-top: var(--safe-area-inset-top);
}

.PanelHeader__after {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  color: var(--header_tint);
}

.PanelHeader--vkapps .PanelHeader__after {
  min-width: 90px;
}

/**
 * iOS
 */
.PanelHeader--ios {
  --panelheader_height: var(--panelheader_height_ios);
}

.PanelHeader--ios .PanelHeader__before {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  opacity: 1;
  transition: opacity 0.3s var(--ios-easing);
  padding: 4px 0 4px 4px;
}

.PanelHeader--ios
  .PanelHeader__before
  .PanelHeaderButton
  + .PanelHeaderButton--primitive {
  margin-left: -6px;
  padding-left: 0;
}

.PanelHeader--ios .PanelHeader__content {
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s var(--ios-easing);
}

.PanelHeader--ios .PanelHeader__content-in {
  font-size: 21px;
}

.PanelHeader--ios .PanelHeader__content > * {
  padding: 0 4px;
}

.PanelHeader--ios.PanelHeader--no-before .PanelHeader__content > * {
  padding-left: 0;
}

.PanelHeader--ios.PanelHeader--no-before .PanelHeader__content {
  padding-left: 8px;
}

.PanelHeader--ios.PanelHeader--no-after .PanelHeader__content > * {
  padding-right: 0;
}

.PanelHeader--ios.PanelHeader--no-after .PanelHeader__content {
  padding-right: 8px;
}

.PanelHeader--ios .PanelHeader__after {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  opacity: 1;
  transition: opacity 0.3s var(--ios-easing);
  padding: 4px 4px 4px 0;
}

.View--ios .View__panel--prev .PanelHeader__before,
.View--ios .View__panel--prev .PanelHeader__after,
.View--ios .View__panel--prev .PanelHeader__content {
  opacity: 0;
}

/**
 * Android
 */
.PanelHeader--android .PanelHeader__before:not(:empty) {
  padding: 4px 0 4px 4px;
}

.PanelHeader--android .PanelHeader__content {
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
}

.PanelHeader--android .PanelHeader__content-in {
  font-size: 23px;
}

.PanelHeader--android .PanelHeader__content > * {
  padding: 0 12px;
}

.PanelHeader--android .Search,
.PanelHeader--vkcom .Search {
  padding: 0 4px;
}

.PanelHeader--android.PanelHeader--no-before .PanelHeader__content > *,
.PanelHeader--vkcom.PanelHeader--no-before .PanelHeader__content > * {
  padding-left: 0;
}

.PanelHeader--android.PanelHeader--no-before .PanelHeader__content {
  padding-left: 16px;
}

.SplitCol--spaced
  .PanelHeader--android.PanelHeader--no-before:not(.ModalPageHeader__in)
  .PanelHeader__content {
  padding-left: 0;
}

.PanelHeader--android.PanelHeader--no-after .PanelHeader__content > *,
.PanelHeader--vkcom.PanelHeader--no-after .PanelHeader__content > * {
  padding-right: 0;
}

.PanelHeader--android.PanelHeader--no-after .PanelHeader__content {
  padding-right: 16px;
}

.SplitCol--spaced
  .PanelHeader--android.PanelHeader--no-after:not(.ModalPageHeader__in)
  .PanelHeader__content {
  padding-right: 0;
}

.PanelHeader--android .PanelHeader__after:not(:empty),
.PanelHeader--vkcom .PanelHeader__after:not(:empty) {
  padding: 4px 4px 4px 0;
}

/**
 * VKCOM
 */
.PanelHeader--vkcom {
  --panelheader_height: var(--panelheader_height_vkcom);

  position: relative;
  z-index: 10;
}

/* TODO: v5.0.0 новая адаптивность */
.PanelHeader--vkcom.PanelHeader--sizeX-regular:not(.ModalPageHeader__in)::after {
  position: absolute;
  left: var(--thin-border);
  right: var(--thin-border);
  bottom: 0;
  height: var(--thin-border);
  background-color: var(--header_background);
  content: "";
}

.PanelHeader--vkcom .PanelHeader__content {
  text-align: center;
}

.PanelHeader--vkcom .PanelHeader__before:not(:empty) {
  padding: 0 0 0 4px;
}

.PanelHeader--vkcom .PanelHeader__before,
.PanelHeader--vkcom .PanelHeader__after {
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
}
