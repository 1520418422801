.FormLayoutGroup--horizontal {
  display: flex;
  padding: 12px var(--vkui--size_base_padding_horizontal--regular);
}

.FormLayoutGroup__removable {
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

/**
 * iOS
 * TODO: v5 удалить
 */
.FormLayoutGroup--ios {
  --formitem_padding: 12px;
}

.FormLayoutGroup--removable {
  padding-left: 0;
  padding-right: 0;
}
