.PullToRefresh {
  flex-grow: 1;
}

.PullToRefresh--refreshing {
  touch-action: none;
  pointer-events: none;
}

.PullToRefresh__controls {
  width: 100%;
  pointer-events: none;
  /* Прямо под шапкой, см https://github.com/VKCOM/VKUI/issues/1207 */
  z-index: 9;
}

.PullToRefresh--ios .PullToRefresh__controls {
  z-index: 0;
}

.PullToRefresh__spinner {
  display: flex;
  margin: auto;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: transform 300ms cubic-bezier(0.1, 0, 0.25, 1),
    opacity 220ms ease-out;
  opacity: 0;
  background: var(
    --background_suggestions,
    var(--vkui--color_background_modal)
  );
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  color: var(--accent, var(--vkui--color_icon_accent));
}

.PullToRefresh--ios .PullToRefresh__spinner {
  border-radius: none;
  box-shadow: none;
  background: none;
  color: var(--icon_outline_secondary, var(--vkui--color_icon_medium));
}

.PullToRefresh--watching .PullToRefresh__spinner {
  transition: opacity 220ms ease-out;
}

.PullToRefresh__spinner-self {
  stroke: currentColor;
}

.PullToRefresh--ios.PullToRefresh--refreshing .PullToRefresh__spinner-self {
  animation: vkui-PullToRefreshToRefreshing 380ms ease-out;
}

.PullToRefresh__spinner-path {
  transform: rotate(-90deg);
  transform-origin: center center;
  transition: stroke-dashoffset 167ms ease-out;
}

.PullToRefresh--watching .PullToRefresh__spinner-path,
.PullToRefresh--refreshing .PullToRefresh__spinner-path {
  transition: none;
}

.PullToRefresh__spinner--on .PullToRefresh__spinner-path {
  animation: vkui-rotator var(--duration) linear infinite;
}

.PullToRefresh__content {
  overflow: hidden;
  transition: transform 400ms var(--ios-easing);
}

.PullToRefresh--watching .PullToRefresh__content {
  transition: none;
}

@keyframes vkui-PullToRefreshToRefreshing {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(0.6);
  }

  90% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
